import React, { useState, useEffect } from "react";
import './Store.css'; // Import the CSS file
import Header from '../setupGuide/loginHeader';

const Store = () => {
    const [practitioners, setPractitioners] = useState(1);
    const [modalVisible, setModalVisible] = useState(false);
    const [showStripePayment, setShowStripePayment] = useState(false);

    const calculatePrice = (tier) => {
        let basePrice = 20;
        if (practitioners === 1) basePrice = 20;
        else if (practitioners >= 2 && practitioners <= 5) basePrice = 40;
        else if (practitioners >= 6 && practitioners <= 10) basePrice = 60;
        else basePrice = 80; // For 10+ practitioners

        if (tier === 'base') return `£${basePrice} per month`;
        if (tier === 'tier1') return `£${basePrice + 30} per month`;
        if (tier === 'tier2') return `£${basePrice + 200} per month`;
    };

    const handleSliderChange = (value) => {
        setPractitioners(value === "10" ? 11 : parseInt(value)); // 10+ handled as 11 in logic
    };

    const handleBuyNow = () => {
        setShowStripePayment(true); // Show the Stripe Pricing Table
    };

    useEffect(() => {
        if (showStripePayment) {
            const script = document.createElement("script");
            script.src = "https://js.stripe.com/v3/pricing-table.js";
            script.async = true;
            document.body.appendChild(script);
        }
    }, [showStripePayment]);

    return (
        <div>
            <Header />
            <div className="store">
                <h2 className="store-heading">AthlioConnect Product Store</h2>

                <div className="practitioner-slider">
                    <label htmlFor="practitioners">
                        Number of Practitioners: {practitioners > 10 ? "10+" : practitioners}
                    </label>
                    <input
                        type="range"
                        id="practitioners"
                        min="1"
                        max="10"
                        value={practitioners > 10 ? 10 : practitioners} // Shows 10+ at top
                        onChange={(e) => handleSliderChange(e.target.value)}
                        className="slider"
                    />
                </div>

                <div className="product-grid">
                    {/* Base Tier Card */}
                    <div className="product-card">
                        <h3 className="product-title">Base Tier</h3>
                        <p className="product-price">{calculatePrice('base')}</p>
                        <button className="buy-now-button" onClick={handleBuyNow}>Buy Base</button>
                    </div>

                    {/* Tier 1 Card */}
                    <div className="product-card">
                        <h3 className="product-title">Tier 1</h3>
                        <p className="product-price">{calculatePrice('tier1')}</p>
                        <button className="buy-now-button" onClick={handleBuyNow}>Buy Tier 1</button>
                    </div>

                    {/* Tier 2 Card */}
                    <div className="product-card">
                        <h3 className="product-title">Tier 2</h3>
                        <p className="product-price">{calculatePrice('tier2')}</p>
                        <button className="buy-now-button" onClick={handleBuyNow}>Buy Tier 2</button>
                    </div>
                </div>

                {/* Stripe Payment Section */}
                {showStripePayment && (
                    <div className="stripe-container">
                        <stripe-pricing-table
                            pricing-table-id="prctbl_1QQEeyRuxSNwOr4wxMJqF61g"
                            publishable-key="pk_live_51Psq2PRuxSNwOr4wmeXfGP8hSagTA5tsh0WSNxd1SJXhQcAP0GRmaL0xm1wpOLNJlTVKVQolnV647KOwJ9aXawnY00r9hYU6jO"
                            success-url="https://digilabsports.com"
                        ></stripe-pricing-table>
                    </div>
                )}

                {/* <p className="free-info">Athletes and Patients get this software for free!</p> */}
            </div>
        </div>
    );
}

export default Store;
