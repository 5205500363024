import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import LandingLayout from './layouts/landing';
import Contact from './contactUs';

import AthlioLayout from './layouts/athlioconnectLayout';
import Store from './AthlioConnect/productStore';
import MerchStore from './AthlioConnect/merchStore';
import SetupGuideLayout from './layouts/setup';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Landing Page as default route */}
          <Route path="/" element={<LandingLayout />} />
          {/* AthlioConnect Route */}
          <Route path='/AthlioConnect' element={<AthlioLayout />} />
          <Route path='/AthlioConnect/Setup-Guide' element={<SetupGuideLayout />} />
          <Route path='/AthlioConnect/merch-store' element={<MerchStore />} />
          <Route path='/AthlioConnect/store' element={<Store />} />

          {/* Contact us route */}
          <Route path='contact' element={<Contact />} />
        </Routes>
      </Router>
      {/* Footer */}
      <footer style={{ textAlign: 'center', padding: '1rem', backgroundColor: '#000' }}>
      </footer>
    </div>
  );
}

export default App;
